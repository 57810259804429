import React from 'react';
import MaskedInput from 'react-text-mask';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextInput,
  Select,
  SelectItem,
  Button,
  InlineNotification,
} from 'carbon-components-react';

/* utils - imports */
import ufs from 'utils/ufs';

/* styles - imports */
import './SignUpStyle.scss';

const SignIn = ({
  success,
  message,
  onChange,
  onValidate,
  name,
  document,
  email,
  password,
  passwordConfirm,
  zipCode,
  street,
  number,
  complement,
  neighborhood,
  city,
  uf,
  onModalSignIn,
  isLoadingAddress,
}) => {
  
  return (
    <div className="sign-up">
      <div className="su">
        <Form>
          <p className="description">
            Para participar é necessário realizar um cadastro com seus dados
            pessoais na plataforma. Garantimos a segurança dos seus dados,
            utilizando-os apenas para identificação em nossa plataforma. Para
            saber mais acesse nossa Política de Privacidade.
          </p>

          <div className="bx--row">
            <div className="bx--col-sm-4">
              <Button
                className="bx--btn bx--btn--tertiary su--sign-in-btn su--btn-w100"
                onClick={onModalSignIn}
              >
                Eu já tenho um cadastro
              </Button>
            </div>
          </div>

          <h2 className="register">Cadastrar</h2>

          <h3>Dados pessoais</h3>

          <FormGroup legendText="Nome">
            <TextInput
              placeholder="Nome completo"
              maxLength={255}
              value={name.value}
              labelText=""
              invalidText={name.message}
              invalid={name.message !== ''}
              onKeyUp={event => onValidate('name', event.target.value)}
              onChange={event => onChange('name', event.target.value)}
            />
          </FormGroup>
          <FormGroup legendText="CPF">
            <MaskedInput
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
              placeholder="000.000.000-00"
              onChange={event => onChange('document', event.target.value)}
              onKeyUp={event => onValidate('document', event.target.value)}
              value={document.value}
              labelText=""
              invalidText={document.message}
              invalid={document.message !== ''}
              render={(ref, props) => (
                <TextInput ref={input => ref(input)} {...props} />
              )}
            />
          </FormGroup>
          <FormGroup legendText="E-mail">
            <TextInput
              placeholder="E-mail para contato"
              maxLength={255}
              value={email.value}
              labelText=""
              invalidText={email.message}
              invalid={email.message !== ''}
              onKeyUp={event => onValidate('email', event.target.value)}
              onChange={event => onChange('email', event.target.value)}
            />
          </FormGroup>
          <FormGroup legendText="Senha">
            <TextInput
              type="password"
              placeholder="Mín. 6 digitos"
              maxLength={255}
              value={password.value}
              labelText=""
              invalidText={password.message}
              invalid={password.message !== ''}
              onKeyUp={event => onValidate('password', event.target.value)}
              onChange={event => onChange('password', event.target.value)}
            />
          </FormGroup>
          <FormGroup legendText="Confirmar senha">
            <TextInput
              type="password"
              placeholder="repita a senha escolhida"
              maxLength={255}
              value={passwordConfirm.value}
              labelText=""
              invalidText={passwordConfirm.message}
              invalid={passwordConfirm.message !== ''}
              onKeyUp={event =>
                onValidate('passwordConfirm', event.target.value)
              }
              onChange={event =>
                onChange('passwordConfirm', event.target.value)
              }
            />
          </FormGroup>

          <h3>Endereço</h3>

          <FormGroup legendText="CEP">
            <MaskedInput
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              placeholder="00000-000"
              value={zipCode.value}
              labelText=""
              invalidText={zipCode.message}
              invalid={zipCode.message !== ''}
              disabled={isLoadingAddress}
              onKeyUp={event => onValidate('zipCode', event.target.value)}
              onChange={event => onChange('zipCode', event.target.value)}
              render={(ref, props) => (
                <TextInput ref={input => ref(input)} {...props} />
              )}
            />
          </FormGroup>
          <FormGroup legendText="Rua">
            <TextInput
              maxLength={255}
              value={street.value}
              invalidText={street.message}
              labelText=""
              invalid={street.message !== ''}
              disabled={isLoadingAddress}
              onKeyUp={event => onValidate('street', event.target.value)}
              onChange={event => onChange('street', event.target.value)}
            />
          </FormGroup>
          <div className="bx--row">
            <div className="bx--col-sm-2">
              <FormGroup legendText="Nº">
                <TextInput
                  maxLength={255}
                  value={number.value}
                  labelText=""
                  invalidText={number.message}
                  invalid={number.message !== ''}
                  onKeyUp={event => onValidate('number', event.target.value)}
                  onChange={event => onChange('number', event.target.value)}
                />
              </FormGroup>
            </div>
            <div className="bx--col-sm-2">
              <FormGroup legendText="Complemento">
                <TextInput
                  maxLength={255}
                  value={complement.value}
                  labelText=""
                  invalidText={complement.message}
                  invalid={complement.message !== ''}
                  onKeyUp={event =>
                    onValidate('complement', event.target.value)
                  }
                  onChange={event => onChange('complement', event.target.value)}
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup legendText="Bairro">
            <TextInput
              maxLength={255}
              value={neighborhood.value}
              labelText=""
              invalidText={neighborhood.message}
              invalid={neighborhood.message !== ''}
              disabled={isLoadingAddress}
              onKeyUp={event => onValidate('neighborhood', event.target.value)}
              onChange={event => onChange('neighborhood', event.target.value)}
            />
          </FormGroup>
          <div className="bx--row">
            <div className="bx--col-sm-2">
              <FormGroup legendText="Cidade">
                <TextInput
                  maxLength={255}
                  value={city.value}
                  labelText=""
                  invalidText={city.message}
                  invalid={city.message !== ''}
                  disabled={isLoadingAddress}
                  onKeyUp={event => onValidate('city', event.target.value)}
                  onChange={event => onChange('city', event.target.value)}
                />
              </FormGroup>
            </div>
            <div className="bx--col-sm-2">
              <Select
                value={uf.value}
                labelText="Estado"
                text="UF"
                invalidText={uf.message}
                invalid={uf.message !== ''}
                disabled={isLoadingAddress}
                onKeyUp={event => onValidate('uf', event.target.value)}
                onChange={event => onChange('uf', event.target.value)}
              >
                <SelectItem disabled value="" text="UF" />
                {ufs.map((item, index) => (
                  <SelectItem key={`selectItem${index}`} {...item} />
                ))}
              </Select>
            </div>
          </div>
          <span>
            Confirmo a veracidade das informações prestadas e aceito os Termos de Uso e Política de Privacidade da Plataforma.
          </span>
          <div className="bx--row">
            <div className="bx--col">
              {!success && message.length !== 0 && (
                <InlineNotification
                  title="Ops, ocorreu algum erro!"
                  subtitle={message.join('\n')}
                  kind="error"
                />
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

SignIn.propTypes = {};

export default SignIn;
