import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextArea,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
} from 'carbon-components-react';

/* utils - imports */
import INPUTS from 'utils/inputs';
import ufs from 'utils/ufs';

/* assets - imports */
import { checkMark } from 'assets/icons';

/* styles - imports */
import '../NewRequestStyle.scss';

class RequestProofsTestimonial extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.state = {
      typeProof: 'testimony',
      description: {
        value: '',
        message: '',
        validations: ['required'],
      },
      name: {
        value: '',
        message: '',
        validations: ['required'],
      },
      cpf: {
        value: '',
        message: '',
        validations: ['required', 'cpf'],
      },
      zipCode: {
        value: '',
        message: '',
        validations: ['required'],
      },
      street: {
        value: '',
        message: '',
        validations: ['required'],
      },
      number: {
        value: '',
        message: '',
        validations: ['required'],
      },
      complement: {
        value: '',
        message: '',
        validations: [],
      },
      neighborhood: {
        value: '',
        message: '',
        validations: ['required'],
      },
      city: {
        value: '',
        message: '',
        validations: ['required'],
      },
      uf: {
        value: '',
        message: '',
        validations: ['required'],
      },
      confirm: {
        value: false,
        message: '',
        validations: ['requiredBoolean'],
      },
    };
  }

  onChange(name, value) {
    const { onZipCode } = this.props;
    if (this.state[name] !== value)
      this.setState(INPUTS.onChange(name, this.state[name], value));
    if (name === 'zipCode') onZipCode(value);
  }

  onValidate(name, value) {
    this.setState({ [name]: INPUTS.onValidate(this.state[name], value) });
  }

  onSubmit() {
    let {
      description,
      name,
      cpf,
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      city,
      uf,
      confirm,
    } = this.state;
    const { typeProof } = this.state;
    const { addRequestProof } = this.props;
    description = INPUTS.onValidate(description, description.value);
    name = INPUTS.onValidate(name, name.value);
    cpf = INPUTS.onValidate(cpf, cpf.value);
    zipCode = INPUTS.onValidate(zipCode, zipCode.value);
    street = INPUTS.onValidate(street, street.value);
    number = INPUTS.onValidate(number, number.value);
    complement = INPUTS.onValidate(complement, complement.value);
    neighborhood = INPUTS.onValidate(neighborhood, neighborhood.value);
    city = INPUTS.onValidate(city, city.value);
    uf = INPUTS.onValidate(uf, uf.value);
    confirm = INPUTS.onValidate(confirm, confirm.value);

    if (
      description.message === '' &&
      name.message === '' &&
      cpf.message === '' &&
      zipCode.message === '' &&
      street.message === '' &&
      number.message === '' &&
      complement.message === '' &&
      neighborhood.message === '' &&
      city.message === '' &&
      uf.message === '' &&
      confirm.message === ''
    )
      addRequestProof({
        typeProof,
        description: description.value,
        witness_name: name.value,
        cpf: cpf.value,
        zip_code: zipCode.value,
        street: street.value,
        number: number.value,
        complement: complement.value,
        neighborhood: neighborhood.value,
        city: city.value,
        state: uf.value,
        confirm_information: confirm.value,
        evidence_type: 'testimony',
      });
    else
      this.setState({
        description,
        name,
        cpf,
        zipCode,
        street,
        number,
        complement,
        neighborhood,
        city,
        uf,
        confirm,
      });
  }

  updateAddress() {
    const { street, neighborhood, city, uf } = this.state;
    const { address } = this.props;

    if (
      address !== null &&
      (address.street !== street.value ||
        address.city !== city.value ||
        address.state !== uf.value ||
        address.neighborhood !== neighborhood.value)
    ) {
      this.setState({
        ...INPUTS.onChange('street', street, address.street),
        ...INPUTS.onChange('city', city, address.city),
        ...INPUTS.onChange('uf', uf, address.state),
        ...INPUTS.onChange('neighborhood', neighborhood, address.neighborhood),
      });
    }
  }

  render() {
    const {
      description,
      name,
      cpf,
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      city,
      uf,
      confirm,
    } = this.state;
    const { isLoadingAddress } = this.props;
    this.updateAddress();

    return (
      <div className="bx--grid">
        <div className="bx--row nr request-proofs-testimonial">
          <div className="bx--col-sm-4 bx--offset-md-2 bx--col-md-4 bx--offset-lg-4 bx--col-lg-8">
            <Form>
              <p className="title">
                Texto de suporte explicando sobre a prova testemunhal
              </p>

              <FormGroup legendText="Motivo de testemunho">
                <TextArea
                  placeholder="Por qual razão o testemunho desta pessoa deveria ser feito?"
                  cols={50}
                  rows={4}
                  value={description.value}
                  labelText=""
                  invalidText={description.message}
                  invalid={description.message !== ''}
                  onKeyUp={event =>
                    this.onValidate('description', event.target.value)
                  }
                  onChange={event =>
                    this.onChange('description', event.target.value)
                  }
                />
              </FormGroup>
              <FormGroup legendText="Nome completo da testemunha">
                <TextInput
                  placeholder="Nome da testemunha"
                  maxLength={255}
                  value={name.value}
                  labelText=""
                  invalidText={name.message}
                  invalid={name.message !== ''}
                  onKeyUp={event => this.onValidate('name', event.target.value)}
                  onChange={event => this.onChange('name', event.target.value)}
                />
              </FormGroup>
              <FormGroup legendText="CPF">
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                  placeholder="000.000.000-00"
                  onChange={event => this.onChange('cpf', event.target.value)}
                  onKeyUp={event => this.onValidate('cpf', event.target.value)}
                  value={cpf.value}
                  labelText=""
                  invalidText={cpf.message}
                  invalid={cpf.message !== ''}
                  render={(ref, props) => (
                    <TextInput ref={input => ref(input)} {...props} />
                  )}
                />
              </FormGroup>
              <FormGroup legendText="CEP">
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                  placeholder="00000-000"
                  value={zipCode.value}
                  labelText=""
                  invalidText={zipCode.message}
                  invalid={zipCode.message !== ''}
                  onKeyUp={event =>
                    this.onValidate('zipCode', event.target.value)
                  }
                  onChange={event =>
                    this.onChange('zipCode', event.target.value)
                  }
                  render={(ref, props) => (
                    <TextInput ref={input => ref(input)} {...props} />
                  )}
                />
              </FormGroup>
              <FormGroup legendText="Rua">
                <TextInput
                  maxLength={255}
                  value={street.value}
                  labelText=""
                  invalidText={street.message}
                  invalid={street.message !== ''}
                  disabled={isLoadingAddress}
                  onKeyUp={event =>
                    this.onValidate('street', event.target.value)
                  }
                  onChange={event =>
                    this.onChange('street', event.target.value)
                  }
                />
              </FormGroup>
              <div className="bx--row">
                <div className="bx--col-sm-2">
                  <FormGroup legendText="Nº">
                    <TextInput
                      maxLength={255}
                      value={number.value}
                      labelText=""
                      invalidText={number.message}
                      invalid={number.message !== ''}
                      onKeyUp={event =>
                        this.onValidate('number', event.target.value)
                      }
                      onChange={event =>
                        this.onChange('number', event.target.value)
                      }
                    />
                  </FormGroup>
                </div>
                <div className="bx--col-sm-2">
                  <FormGroup legendText="Complemento">
                    <TextInput
                      maxLength={255}
                      value={complement.value}
                      labelText=""
                      invalidText={complement.message}
                      invalid={complement.message !== ''}
                      onKeyUp={event =>
                        this.onValidate('complement', event.target.value)
                      }
                      onChange={event =>
                        this.onChange('complement', event.target.value)
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <FormGroup legendText="Bairro">
                <TextInput
                  maxLength={255}
                  value={neighborhood.value}
                  labelText=""
                  invalidText={neighborhood.message}
                  invalid={neighborhood.message !== ''}
                  disabled={isLoadingAddress}
                  onKeyUp={event =>
                    this.onValidate('neighborhood', event.target.value)
                  }
                  onChange={event =>
                    this.onChange('neighborhood', event.target.value)
                  }
                />
              </FormGroup>
              <div className="bx--row">
                <div className="bx--col-sm-2">
                  <FormGroup legendText="Cidade">
                    <TextInput
                      maxLength={255}
                      value={city.value}
                      labelText=""
                      invalidText={city.message}
                      invalid={city.message !== ''}
                      disabled={isLoadingAddress}
                      onKeyUp={event =>
                        this.onValidate('city', event.target.value)
                      }
                      onChange={event =>
                        this.onChange('city', event.target.value)
                      }
                    />
                  </FormGroup>
                </div>
                <div className="bx--col-sm-2">
                  <Select
                    value={uf.value}
                    labelText="Estado"
                    text="UF"
                    invalidText={uf.message}
                    invalid={uf.message !== ''}
                    disabled={isLoadingAddress}
                    onKeyUp={event => this.onValidate('uf', event.target.value)}
                    onChange={event => this.onChange('uf', event.target.value)}
                  >
                    <SelectItem disabled value="" text="UF" />
                    {ufs.map((item, index) => (
                      <SelectItem key={`selectItem${index}`} {...item} />
                    ))}
                  </Select>
                </div>
              </div>
              <Checkbox
                id="confirm"
                name="confirm"
                labelText="Confirmo a veracidade das informações prestadas."
                checked={confirm.value}
                onChange={value => this.onChange('confirm', value)}
              />
              <div className="bx--row">
                <div className="bx--col">
                  <div className="bx--btn--container-center">
                    <Button href="#solicitar-testemunho" onClick={() => this.onSubmit()}>
                      Solicitar testemunho
                      {checkMark()}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

RequestProofsTestimonial.propTypes = {
  addRequestProof: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RequestProofsTestimonial;
