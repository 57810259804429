import React from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextArea,
  Button,
  InlineNotification,
  InlineLoading,
  TextInput,
  Select,
  SelectItem,
  FileUploaderButton,
} from 'carbon-components-react';

/* core components - imports */
import BoxLink from 'components/core/BoxLink';
import BoxButton from 'components/core/BoxButton';

/* assets - imports */
import { checkMark } from 'assets/icons';

/* styles - imports */
import '../NewRequestStyle.scss';

const DataForm = ({
  form,
  onChange,
  onValidate,
  onSubmit,
  setForm,
  isLoading,
  success,
  message,
  removeEvidence,
  removeRequestProof,
}) => {
  return (
    <div className="bx--grid">
      <div className="bx--row">
        <div
          className="bx--col-sm-4 bx--offset-md-2
        bx--col-md-4 bx--offset-lg-4 bx--col-lg-8"
        >
          <Form>
            <FormGroup legendText="Pedido">
              <TextArea
                placeholder="O que você quer?"
                cols={50}
                rows={4}
                value={form.description.value}
                labelText=""
                invalidText={form.description.message}
                invalid={form.description.message !== ''}
                onKeyUp={event => onValidate('description', event.target.value)}
                onChange={event => onChange('description', event.target.value)}
              />
            </FormGroup>
            <FormGroup legendText="Fundamentos">
              <TextArea
                placeholder="Como? Onde? Quando? Por quê?
                Em quais as circustâncias do fato descrito."
                cols={50}
                rows={4}
                value={form.fundamentals.value}
                labelText=""
                invalidText={form.fundamentals.message}
                invalid={form.fundamentals.message !== ''}
                onKeyUp={event =>
                  onValidate('fundamentals', event.target.value)
                }
                onChange={event => onChange('fundamentals', event.target.value)}
              />
            </FormGroup>
            <FormGroup legendText="Título do anexo">
              <TextInput
                labelText=""
                placeholder="Escolha um nome para referência"
                maxLength={255}
                value={form.filenameName.value}
                onChange={event => onChange('filenameName', event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Select
                value={form.filenameType.value}
                labelText="Tipo de anexo"
                onKeyUp={event =>
                  this.onValidate('filenameType', event.target.value)
                }
                onChange={event => {
                  if (event.target.value === 'video')
                    onChange('filename', null);
                  else onChange('filenameUrl', '');
                  onChange('filenameType', event.target.value);
                }}
              >
                <SelectItem disabled value="" text="Selecione" />
                <SelectItem value="pdf" text="Documento PDF" />
                <SelectItem value="image" text="Imagem" />
                <SelectItem value="video" text="Vídeo" />
              </Select>
            </FormGroup>
            {form.filenameType.value !== '' &&
              form.filenameType.value !== 'video' && (
                <FormGroup legendText="">
                  <FileUploaderButton
                    name="evidenceFile"
                    className="bx--file-uploader-button"
                    buttonKind="tertiary"
                    labelText="Anexar Arquivo"
                    value={form.filename.value}
                    onChange={event =>
                      onChange('filename', event.target.files[0])
                    }
                  />
                </FormGroup>
              )}
            {form.filenameType.value !== '' &&
              form.filenameType.value === 'video' && (
                <FormGroup legendText="Vídeo">
                  <TextInput
                    labelText=""
                    placeholder="URL do vídeo"
                    maxLength={255}
                    value={form.filenameUrl.value}
                    onChange={event =>
                      onChange('filenameUrl', event.target.value)
                    }
                  />
                </FormGroup>
              )}
            <BoxLink
              key="dataFormBoxLinkAttach"
              title="Anexar provas"
              titleMore="Provas anexadas"
              more="Anexar outra prova"
              description="Inclua (documentos, arquivos, vídeos,
              áudios) que você já tenha em mãos para embasar o seu pedido."
              list={form.attachEvidences}
              onClick={() => setForm(2)}
              removeItem={removeEvidence}
            />
            <BoxLink
              key="dataFormBoxLinkRequest"
              title="Solicitar provas"
              titleMore="Provas solicitadas"
              more="Solicitar outra prova"
              description="Clique para solicitar a produção de
              provas que podem embasar o seu pedido."
              list={form.requestProofs}
              onClick={() => setForm(3)}
              removeItem={removeRequestProof}
            />
            <FormGroup
              legendText="Em conclusão: você é favorável
              ao ajuizamento da ação coletiva?"
              className="nr conclusion"
            >
              <div className="bx--row no-margin">
                <div className="bx--col-sm-1 bx--col-lg-2 bx--no-gutter--left">
                  <BoxButton
                    name="conclusion-yes"
                    labelText="Sim"
                    checked={form.favorable.value === 'yes'}
                    onClick={() => onChange('favorable', 'yes')}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-lg-2 bx--no-gutter--left">
                  <BoxButton
                    name="conclusion-no"
                    labelText="Não"
                    checked={form.favorable.value === 'no'}
                    onClick={() => onChange('favorable', 'no')}
                  />
                </div>
                <div className="bx--col-sm-1 bx--col-lg-2 bx--no-gutter--left">
                  <BoxButton
                    name="conclusion-other"
                    labelText="Outro"
                    checked={form.favorable.value === 'other'}
                    onClick={() => onChange('favorable', 'other')}
                  />
                </div>
              </div>
            </FormGroup>
          </Form>
        </div>
      </div>

      <div className="bx--row">
        <div className="bx--col-sm-4 bx--offset-md-2 bx--col-md-4 bx--offset-lg-4 bx--col-lg-8">
          {!success && message.length !== 0 && (
            <InlineNotification
              title="Ops, ocorreu algum erro!"
              subtitle={message.join('\n')}
              kind="error"
            />
          )}
          <div className="bx--btn--container-center">
            {isLoading || success ? (
              <InlineLoading description="Enviando..." success={success} />
            ) : (
              <Button href="#enviar-pedido" onClick={() => onSubmit()}>
                Enviar Pedido
                {checkMark()}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const field = PropTypes.shape({
  value: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.string).isRequired,
}).isRequired;

DataForm.propTypes = {
  form: PropTypes.shape({
    description: field,
    fundamentals: field,
    favorable: field,
    attachEvidences: PropTypes.arrayOf({
      name: PropTypes.string.isRequired,
      file: PropTypes.string.isRequired,
    }),
    requestProofs: PropTypes.arrayOf({
      name: PropTypes.string.isRequired,
      file: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
};

export default DataForm;
