import { takeLatest, put } from 'redux-saga/effects';
import ENDPOINTS from 'config/endpoints';
import axios from 'axios';

import { Types } from '../ducks/audiences';

function getAudiences(page) {
  return axios
    .get(`${ENDPOINTS.GET_AUDIENCES}?page=${page}&perPage=1000`)
    .then(res => {
      return {
        audiences: res.data.audiences.sort((a, b) => a.title.localeCompare(b.title))
      }
    })
    .catch(error => {
      const response = error.response.status === 404 ? error.response : error;
      throw response;
    });
}

function getAudience(id) {
  const accessToken = localStorage.getItem('accessToken');
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');

  return axios({
    method: 'GET',
    url: ENDPOINTS.GET_AUDIENCE(id),
    headers: {
      'access-token': accessToken,
      client,
      uid,
    },
  })
    .then(res => res.data)
    .catch(error => {
      const response = error.response.status === 404 ? error.response : error;
      throw response;
    });
}

function* audiencesList({ payload }) {
  try {
    const response = yield getAudiences(payload.page);
    yield put({
      type: Types.AUDIENCES_LIST_SUCCESS,
      items: response.audiences,
    });
  } catch (e) {
    yield put({
      type: Types.AUDIENCES_LIST_FAIL,
      error: 'Erro ao listar as audiências',
    });
  }
}

function* audience({ payload }) {
  try {
    const response = yield getAudience(payload.id);
    yield put({ type: Types.AUDIENCE_SUCCESS, audience: response });
  } catch (e) {
    yield put({
      type: Types.AUDIENCE_FAIL,
      error: 'Audiência não encontrada!',
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.AUDIENCES_LIST, audiencesList);
  yield takeLatest(Types.AUDIENCE, audience);
}
