import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* carbon - imports */
import {
  Form,
  FormGroup,
  TextArea,
  Checkbox,
  Button,
} from 'carbon-components-react';

/* utils - imports */
import INPUTS from 'utils/inputs';

/* assets - imports */
import { checkMark } from 'assets/icons';

/* styles - imports */
import '../NewRequestStyle.scss';

class RequestProofsDocumentary extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.state = {
      typeProof: 'documentary',
      document: {
        value: '',
        message: '',
        validations: ['required'],
      },
      person: {
        value: '',
        message: '',
        validations: ['required'],
      },
      proof: {
        value: '',
        message: '',
        validations: ['required'],
      },
      confirm: {
        value: false,
        message: '',
        validations: ['requiredBoolean'],
      },
    };
  }

  onChange(name, value) {
    if (this.state[name].value !== value)
      this.setState(INPUTS.onChange(name, this.state[name], value));
  }

  onValidate(name, value) {
    this.setState({ [name]: INPUTS.onValidate(this.state[name], value) });
  }

  onSubmit() {
    let { document, person, proof, confirm } = this.state;
    const { typeProof } = this.state;
    const { addRequestProof } = this.props;

    document = INPUTS.onValidate(document, document.value);
    person = INPUTS.onValidate(person, person.value);
    proof = INPUTS.onValidate(proof, proof.value);
    confirm = INPUTS.onValidate(confirm, confirm.value);

    if (
      document.message === '' &&
      person.message === '' &&
      proof.message === '' &&
      confirm.message === ''
    )
      addRequestProof({
        typeProof,
        document_description: document.value,
        owner_document: person.value,
        evidence_to_prove: proof.value,
        confirm_information: confirm.value,
        evidence_type: 'documentary',
      });
    else this.setState({ document, person, proof, confirm });
  }

  render() {
    const { document, person, confirm, proof } = this.state;

    return (
      <div className="bx--grid">
        <div className="bx--row nr request-proofs-testimonial">
          <div className="bx--col-sm-4 bx--offset-md-2 bx--col-md-4 bx--offset-lg-4 bx--col-lg-8">
            <Form>
              <p className="title">
                Texto de suporte explicando sobre a prova pericial
              </p>

              <FormGroup legendText="Qual é o documento?">
                <TextArea
                  placeholder="Qual o documento você pretende requerer?"
                  cols={50}
                  rows={4}
                  value={document.value}
                  labelText=""
                  invalidText={document.message}
                  invalid={document.message !== ''}
                  onKeyUp={event =>
                    this.onValidate('document', event.target.value)
                  }
                  onChange={event =>
                    this.onChange('document', event.target.value)
                  }
                />
              </FormGroup>
              <FormGroup legendText="Quem detêm este documento?">
                <TextArea
                  placeholder="**** FALTA PLACEHOLDER ****"
                  cols={50}
                  rows={4}
                  value={person.value}
                  labelText=""
                  invalidText={person.message}
                  invalid={person.message !== ''}
                  onKeyUp={event =>
                    this.onValidate('person', event.target.value)
                  }
                  onChange={event =>
                    this.onChange('person', event.target.value)
                  }
                />
              </FormGroup>
              <FormGroup legendText="O que você quer provar?">
                <TextArea
                  placeholder="O que você quer provar com este  documento?"
                  cols={50}
                  rows={4}
                  value={proof.value}
                  labelText=""
                  invalidText={proof.message}
                  invalid={proof.message !== ''}
                  onKeyUp={event =>
                    this.onValidate('proof', event.target.value)
                  }
                  onChange={event => this.onChange('proof', event.target.value)}
                />
              </FormGroup>
              <Checkbox
                id="confirm"
                name="confirm"
                labelText="Confirmo a veracidade das informações prestadas."
                checked={confirm.value}
                onChange={value => this.onChange('confirm', value)}
              />
              <div className="bx--row">
                <div className="bx--col">
                  <div className="bx--btn--container-center">
                    <Button href="#solicitar-documento" onClick={() => this.onSubmit()}>
                      Solicitar documento
                      {checkMark()}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

RequestProofsDocumentary.propTypes = {
  addRequestProof: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RequestProofsDocumentary;
